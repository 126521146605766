<template>
  <div class="container">
      <section v-if="$route.query.request   == 'successful'">
        <article class="payment_page">
            <div>
                <el-result 
                    icon="success" 
                    title="Your Reservation has been Successfully Submitted" 
                    subTitle="Thank you for your reservation! We are pleased to inform you that your reservation has been successfully submitted.">
                    <!-- <template slot="extra">
                        <el-button type="primary" size="medium">Back</el-button>
                    </template> -->
                </el-result>

                <div class="text-center">
                    <p>We will review the details and get back to you shortly with a confirmation.</p>
                    <p>Thank you for choosing us!</p>
                </div>
            </div>
        </article>
      </section>

      <section v-else-if="$route.query.request   == 'cancelled'">
        <article class="payment_page">
            <div>
                <el-result icon="warning"
                    title="Your Payment has been cancelled." 
                    subTitle="Your Transaction has cancelled. Please check out your Balance or try again">
                <!-- <template slot="extra">
                    <el-button type="primary" size="medium">Back</el-button>
                </template> -->
                </el-result>
            </div>
        </article>
      </section>

      <section v-else-if="$route.query.request   == 'failed'">
        <article class="payment_page">
            <div>
                <el-result icon="error"
                    title="Your Payment has failed." 
                    subTitle="Your Transaction has failed. Please check out your Balance">
                <!-- <template slot="extra">
                    <el-button type="primary" size="medium">Back</el-button>
                </template> -->
                </el-result>
            </div>
        </article>
      </section>

  </div>
</template>

<script>
    export default {
        data() {
            return {
                value: "",
            }
        },
        

        watch: {
            "$route.query.request  ": {
                handler: function (status) {
                    this.onQueryChange();
                },
                deep: true,
                immediate: true,
            },
        },

        mounted() {
            this.onQueryChange();
        },

        methods: {
            onQueryChange() {
                if(this.$route.query.request   == 'successful') {
                    this.$notify({
                        title: "Success",
                        message: "Transaction Successful.",
                        type: "success",
                    });
                }
                else if(this.$route.query.request   == 'cancelled') {
                    this.$notify({
                        title: "Cancelled",
                        message: "Transaction cancelled.",
                        type: "warning",
                    });
                }
                else if(this.$route.query.request   == 'failed') {
                    this.$notify({
                        title: "Failed",
                        message: "Transaction failed.",
                        type: "error",
                    });
                }
                else {
                    this.$router.push({path: '/'});
                }
            }
        }
    }
</script>

<style scoped>
    .payment_page {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>