<template>
  <div>
    <ParallaxComponent 
      :selectedImage="selectedImage"
      :title="'Our Bungalows'"
      :paragraph="'Experience the wilderness in our affordable self-contained rooms, each with a private veranda offering stunning views and beautifully appointed bathrooms. Enjoy the perfect blend of comfort and nature for an unforgettable safari adventure!'"
    />

    <section>
      <article class="accommodation-body">
        <div class="container text-center pb-5">
          <h2>The Rooms</h2>
        </div>

        <section>
          <article>
            <div class="room-accommodation-grid">
              <div>
                <img src="../../assets/images/double_occupancy.webp" />
              </div>
              <div class="d-flex align-items-center">
                <div>
                  <h2>Double Occupancy</h2>
                  <p class="sub-title">Perfect for friends, colleagues, or couples seeking a romantic retreat.</p>
                  <p>
                    At Acacia Wilderness Mweya, double occupancy rooms offer a comfortable and serene stay for two guests. Nestled in the heart of nature, 
                    the rooms are spacious and include either one large bed or two twin beds. Perfect for couples or friends, 
                    guests can enjoy stunning views and a relaxing environment after a day of adventure in the wilderness.
                  </p>
                  <p>Occupancy: 2 people</p>
                  <!-- <div class="room-rate">
                    <div>
                      ROOM RATE
                    </div>
                    <div>
                      ${{ doubleOccupancy.pricePerNight }}
                    </div>
                  </div> -->
                  <div>
                        <!-- <el-button 
                          class="acacia-btn"
                          
                          @click="$router.push({path: `accommodation-detail/${doubleOccupancy.categoryID}/${doubleOccupancy.userName}`})"

                          
                          >BOOK NOW</el-button > -->

                                                  <el-button 
                          class="acacia-btn"
                          @click="openEmailDouble"
                        >
                          BOOK NOW
                        </el-button>

                  </div>
                </div>
              </div>
            </div>

            <div class="room-accommodation-grid-reserve">
              <div class="d-flex align-items-center">
                <div>
                  <h2>Single Occupancy</h2>
                  <p class="sub-title">Enjoy your own private space amidst nature</p>
                  <p>
                    At Acacia Wilderness Mweya, single occupancy rooms provide a tranquil and private retreat for solo travelers. 
                    These rooms offer all the comforts needed for a relaxing stay, including a cozy bed, modern amenities, 
                    and beautiful views of the surrounding wilderness. Ideal for guests seeking solitude and a peaceful escape in nature.
                  </p>
                  <p>Occupancy: 1 person</p>
                  <!-- <div class="room-rate">
                    <div>
                      ROOM RATE
                    </div>
                    <div>
                      ${{ singleOccupancy.pricePerNight }}
                    </div>
                  </div> -->
                  
                  <div>
                        <!-- <el-button 
                          class="acacia-btn"
                          @click="$router.push({path: `accommodation-detail/${singleOccupancy.categoryID}/${singleOccupancy.userName}`})"
                          >BOOK NOW</el-button
                        > -->

                        
                        <el-button 
                          class="acacia-btn"
                          @click="openEmailSingle"
                        >
                          BOOK NOW
                          </el-button>


                  </div>
                </div>
              </div>
              <div>
                <img src="../../assets/images/single-occupancy.webp" />
              </div>
            </div>

            <div class="room-accommodation-grid">
              <div>
                <img src="../../assets/images/accomodation-1.webp" />
              </div>
              <div class="d-flex align-items-center">
                <div>
                  <h2>Family Suites</h2>
                  <p class="sub-title">Ideal for families, our interconnected rooms provide ample space and privacy.</p>
                  <p>
                    At Acacia Wilderness Mweya, family occupancy rooms are designed to comfortably accommodate families or small groups. 
                    These spacious rooms offer multiple beds, ample living space, and modern amenities, ensuring a comfortable and enjoyable stay for everyone. 
                    Surrounded by the beauty of the wilderness, it's the perfect setting for family adventures and relaxation.
                  </p>
                  <p>Occupancy: 4 people</p>
                  <!-- <div class="room-rate">
                    <div>
                      ROOM RATE
                    </div>
                    <div>
                      ${{ familyOccupancy.pricePerNight }}
                    </div>
                  </div> -->
                  <div>
                        <!-- <el-button 
                          class="acacia-btn"
                          @click="$router.push({path: `accommodation-detail/${familyOccupancy.categoryID}/${familyOccupancy.userName}`})"
                          >BOOK NOW</el-button
                        > -->

                        <el-button 
                          class="acacia-btn"
                          @click="openEmailFamily"
                        >
                          BOOK NOW
                          </el-button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </article>
    </section>

    <!-- <section>
          <article class="the-best-alternative">
            <div class="container">
              <div class="the-best-alternative-grid">
                <div>
                  <div>
                    <h2>EXTRA BED</h2>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="">
                      <div>
                        <p>
                          <strong>Available to accommodate additional guests.</strong>
                        </p>
                          <i>
                            "For groups or families needing extra space, we offer additional beds to ensure everyone has a comfortable 
                            place to sleep. Whether you have an extra guest or just need more sleeping arrangements, our extra bed option 
                            provides the flexibility you need."
                          </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
    </section> -->

    <!-- <ReservationArticleComponent/> -->
  </div>
</template>

<script>
// import ReservationArticleComponent from "../../components/website/home/reservation-article-component.vue";
  import ParallaxComponent from "../../components/website/pallarax-component.vue";
  export default {
  components: {
    // ReservationArticleComponent,
    ParallaxComponent
  },

    data() {
      return {
        selectedImage: require("../../assets/images/accomodation-outdoor.webp"),
      loading: false,
      isLoading: false,
      categories: [],
      singleOccupancy: {},
      doubleOccupancy: {},
      familyOccupancy: {},
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {

    openEmailDouble() {
    const subject = encodeURIComponent("DOUBLE OCCUPANCY RESERVATION");
    const body = encodeURIComponent("Hello Acacia Wilderness Mweya,");
    const email = "reservations@acaciawildernessmweya.com";

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  },

  openEmailSingle() {
    const subject = encodeURIComponent("SINGLE OCCUPANCY RESERVATION");
    const body = encodeURIComponent("Hello Acacia Wilderness Mweya,");
    const email = "reservations@acaciawildernessmweya.com";

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  },

  openEmailFamily() {
    const subject = encodeURIComponent("FAMILY OCCUPANCY RESERVATION");
    const body = encodeURIComponent("Hello Acacia Wilderness Mweya,");
    const email = "reservations@acaciawildernessmweya.com";

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  },
    async getCategories() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `categories`
        );
        if (request.data.success && request.data.message == "ROOM_CATEGORIES_FETCHED_SUCCESSFULLY") {
          this.categories = request.data.categories;
          this.singleOccupancy = this.categories.filter((item) => {
            return item.userName === 'single-occupancy'
          })[0];
          this.doubleOccupancy = this.categories.filter((item) => {
            return item.userName === 'double-occupancy'
          })[0];
          this.familyOccupancy = this.categories.filter((item) => {
            return item.userName === 'family-occupancy'
          })[0];
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        // this.$notify({
        //   title: "Operation Failed",
        //   message: "Unable to fetch categories now, please try again",
        //   type: "error",
        // });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.accommodation-body{
  padding-top: 50px;
  padding-bottom: 50px;
}
.accommodation-body h2{
  color: #17351E;
  font-size: 2.5em;
  font-weight: 500;
}

.room-accommodation-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
}

.room-accommodation-grid > div{
  height: 80vh;
}

.room-accommodation-grid > div p{
  font-size: 1.2em;
}

.room-accommodation-grid > div img{
  height: 80vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.room-accommodation-grid > div:nth-child(2){
  padding: 50px;
}

.room-accommodation-grid-reserve {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
}

.room-accommodation-grid-reserve > div{
  height: 80vh;
}

.room-accommodation-grid-reserve > div p{
  font-size: 1.2em;
}

.room-accommodation-grid-reserve > div img{
  height: 80vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.room-accommodation-grid-reserve > div:first-child{
  padding: 50px;
}
.acacia-btn {
  background-color: #17351E;
  border: 2px solid #17351E;
  color: #FFF;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 50%;
}

.acacia-btn:hover {
  background-color: #DDD0A9;
  border: 2px solid #DDD0A9;
  color: white !important;
  transition: 0.5s;
}

.room-rate{
  width: 50%;
  margin-bottom: 30px;
}

.room-rate >div{
  border: 1px solid #DDD0A9;
  padding: 20px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
}

.room-rate >div:first-child{
  background-color: #DDD0A9;
  color: white;
}



.the-best-alternative {
  background-color: #F4F1ED;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
}

.the-best-alternative-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}

.the-best-alternative-grid > div{
  display: flex;
  align-items: center;

}

.the-best-alternative-grid > div h2{
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 800;
  color: #17351E;
}

.the-best-alternative-grid > div:nth-child(2) p{
  font-size: 1.2em;
  font-weight: 400;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  
  .the-best-alternative {
    background-color: #DDD0A9;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2{
    text-align: center;
  }
  .the-best-alternative-grid > div p{
    text-align: center;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    grid-auto-flow: row;
  }
  .room-accommodation-grid > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid > div:first-child{
    height: 40vh;
  }
  .room-accommodation-grid > div:first-child img{
    height: 40vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    height: 100%;
  }
  .room-accommodation-grid-reserve > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid-reserve > div:nth-child(2){
    height: 40vh;
    grid-row: 1;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img{
    height: 40vh;
  }
  .room-accommodation-grid-reserve > div:first-child{
    height: 100%;
    padding-top: 50px;
  }
  .room-accommodation-grid-reserve > div img{
    height: 50vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    padding: 0px;
  }
  .room-accommodation-grid-reserve > div:first-child{
    padding: 0px;
  }
  .room-accommodation-grid > div p{
    font-size: 1.0em;
  }
  .room-accommodation-grid-reserve > div p{
    font-size: 1.0em;
  }
  .accommodation-body h2{
    padding-top: 50px;
  }
  .acacia-btn {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .the-best-alternative {
    background-color: #DDD0A9;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2{
    text-align: center;
  }
  .the-best-alternative-grid > div p{
    text-align: center;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    grid-auto-flow: row;
  }
  .room-accommodation-grid > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid > div:first-child{
    height: 40vh;
  }
  .room-accommodation-grid > div:first-child img{
    height: 40vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    height: 100%;
  }
  .room-accommodation-grid-reserve > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid-reserve > div:nth-child(2){
    height: 40vh;
    grid-row: 1;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img{
    height: 40vh;
  }
  .room-accommodation-grid-reserve > div:first-child{
    height: 100%;
    padding-top: 50px;
  }
  .room-accommodation-grid-reserve > div img{
    height: 50vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    padding: 0px;
  }
  .room-accommodation-grid-reserve > div:first-child{
    padding: 0px;
  }
  .room-accommodation-grid > div p{
    font-size: 1.0em;
  }
  .room-accommodation-grid-reserve > div p{
    font-size: 1.0em;
  }
  .accommodation-body h2{
    padding-top: 50px;
  }
  .acacia-btn {
    width: 100%;
  }

}
</style>