<template>
  <div>
    <section>
          <article class="the-best-alternative">
            <div class="acacia-container">
              <div class="the-best-alternative-grid">
                <div>
                  <div>
                    <h2>REVIEWS AND TESTIMONIALS</h2>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="review-grid">
                      <div>
                          <i>
                            "Beautiful lodge with stunning views of the Kazinga Channel. The staff is attentive, the rooms are luxurious, 
                            and the safari experience is unforgettable. Perfect for a peaceful and nature-filled retreat."
                          </i>
                        <p>
                          <strong>– Joseph M.</strong>
                        </p>
                      </div>
                      <div>
                      
                          <i>
                            "Amazing location with breathtaking wildlife views. The food was delicious, and the staff made us feel right at home. 
                            Acacia Wilderness Mweya is the perfect getaway for nature lovers and adventurers alike."
                          </i>
                        <p>
                          <strong>– James N.</strong>
                        </p>
                        
                      </div>
                      <div>
                        <i>
                          "We loved our stay at Acacia Wilderness Mweya. The rooms were spacious and clean, the views were spectacular, 
                          and the safari tours were well-organized. A truly memorable experience in the heart of nature."
                        </i>
                        <p>
                          <strong>– Claire S.</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

      reviews: [
        {
          id: "1",
          reviewer_name: "Kim Gardner from France",
          description: "The bed and everything in the room was very clean and comfortable. The free wifi was an acceptable speed. Housekeeping was very good. The free mini-bar was an enjoyable treat, with candies and all sorts of drinks. Especially the 4 bottles of water when the temperature was 90+F everyday haha",
          image: require("../../../assets/images/download.png")
        },
        {
          id: "2",
          reviewer_name: "Jennifer Lawrence from UK",
          description: "The service provided was outstanding and they certainly went above and beyond to make sure our stay was both enjoyable and a 5 star experience. Thank you so much Xavier Schaeffer and your team. Thank you Jessica and Bonnie for looking after us in the club lounge...our drinks were never empty. Also a special thank you to Harry Chan and Lung Wong in concierge for all your assistance and great tips on navigating our way through",
          image: require("../../../assets/images/download.png")
        },
        {
          id: "3",
          reviewer_name: "Linda Smith from US",
          description: "I booked this hotel based on the reviews of the service and the location. The location was incredible. The room was spacious and very comfortable. The hotel offers a club for guests if they arrive very early. I wish the massage chair was there the day we were using the club though.",
          image: require("../../../assets/images/download.png")
        },
      ],

    };
  },
  methods: {
    
  },
};
</script>

<style scoped>

.acacia-container {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
}

.the-best-alternative {
  background-color: #F4F1ED;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
}

.the-best-alternative-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}

.the-best-alternative-grid > div{
  display: flex;
  align-items: center;

}

.the-best-alternative-grid > div h2{
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 800;
  color: #17351E;
}

.the-best-alternative-grid > div p{
  font-size: 1em;
  font-weight: 300;
  color: #FFF;
}

.the-best-alternative-grid > div:nth-child(2) p{
  font-size: 1em;
  font-weight: 400;
}

.acacia-btn {
  background-color: transparent;
  color: #17351E;
  border: 2px solid #17351E;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
}

.acacia-btn:hover {
  background-color: #17351E;
  color: white !important;
  transition: 0.5s;
}

.acacia-btn-inverse {
  background-color: #17351E;
  color: white !important;
  border: 2px solid #17351E;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
}

.acacia-btn-inverse:hover {
  background-color: transparent;
  color: #17351E;
  transition: 0.5s;
}

.review-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.review-grid >div{
  background-color: #DDD0A9;
  color: #17351E;
  border: 1px solid #DDD0A9;
  /* height: 300px; */
  padding: 20px;
  margin-bottom: 20px;
}

.review-grid >div p{
  color: #17351E;
  /* text-wrap-style: ita; */
}
    
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {

      
    .acacia-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .the-best-alternative {
    background-color: #F4F1ED;
    padding-top: 50px;
    padding-bottom: 0px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2{
    text-align: center;
  }
  .the-best-alternative-grid > div p{
    text-align: center;
  }

.review-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
      
  .acacia-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .the-best-alternative {
    background-color: #F4F1ED;
    padding-top: 50px;
    padding-bottom: 0px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2{
    text-align: center;
  }
  .the-best-alternative-grid > div p{
    text-align: center;
  }

.review-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
  }
</style>