<template>
  <div>
    <div>
      <div class="containere">
        <section>
          <article class="the-best-alternative">
            <div class="container">
              <div class="the-best-alternative-grid">
                <div>
                  <div>
                    <!-- <h2>Welcome to Acacia Wilderness Mweya</h2>
                    <p>
                      Step into a world where the wild whispers in the breeze
                      and the vast skies stretch beyond the horizon. At Acacia
                      Wilderness Mweya, nature’s symphony surrounds you—where
                      dawn greets the savannah with golden light and the rhythm
                      of the wild stirs your soul. Here, amidst the heart of
                      Queen Elizabeth National Park, simplicity meets serenity.
                      Embrace the call of the wild, relax in our humble yet cozy
                      sanctuary, and let the untamed beauty of Uganda leave its
                      mark on your heart.
                    </p> -->

                    <h2>Welcome to Acacia Wilderness Mweya</h2>
                    <p>
                      Where the wild calls and the sky stretches endlessly,
                      Acacia Wilderness Mweya offers a serene escape. Nestled in
                      Queen Elizabeth National Park, our cozy lodge invites you
                      to unwind, embrace nature's rhythm, and let the beauty of
                      Uganda leave an unforgettable mark on your journey.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <p>
                      Nestled deep within the heart of Queen Elizabeth National
                      Park, Acacia Wilderness Mweya offers an unparalleled
                      adventure experience for every nature enthusiast. Our
                      lodge, perched on a scenic plateau, provides breathtaking
                      views of the surrounding savannah and its unique wildlife
                      ecosystems.
                    </p>
                    <p>
                      Once here, guests can encounter up to 600 species of
                      birds, unique wildlife, and extraordinary flora exclusive
                      to this part of the world, all set against the backdrop of
                      the Kazinga Channel and the sweeping savannah plains.
                      Embark on guided treks through hidden trails, explore the
                      shores of the channel, rise at dawn for rare bird
                      sightings, or take a boat safari for an unforgettable
                      wildlife experience.
                    </p>
                    <div class="to-the-center-mobile">
                      <div>
                        <el-button
                          class="acacia-btn"
                          @click="$router.push({ path: 'dining' })"
                          >THE DINING</el-button
                        >
                        <el-button
                          class="acacia-btn-inverse"
                          @click="$router.push({ path: 'accommodation' })"
                          >ROOMS</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>

        <section>
          <article>
            <div class="the-lodge-grid-section">
              <div class="bg-image">
                <img src="../../../assets/images/DSC09241.webp" />
              </div>
              <div>
                <div>
                  <p class="heading-two segoe">A Home Away from Home</p>
                  <p class="content-paragraph segoe">
                    Immerse yourself in unparalleled comfort and breathtaking
                    lake views at our exclusive savannah lodge.
                  </p>
                  <p class="content-paragraph segoe">
                    Experience the thrill of witnessing majestic wildlife in
                    their natural habitat, with elephants, buffalos, waterbucks,
                    and warthogs grazing around the property 24/7. Discover the
                    perfect blend of luxury and adventure, creating memories
                    that will last a lifetime.
                  </p>
                  <button class="btn btn-lg content-btn mt-5 segoe"  @click="$router.push({ path: '/accommodation' })">
                    EXPLORE OUR ROOMS
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <p class="heading-two segoe">
                    Prime Location in Queen Elizabeth National Park
                  </p>
                  <p class="content-paragraph segoe" style="color: #666666">
                    Nestled in the heart of Queen Elizabeth National Park, our
                    lodge offers stunning views of Lake Edward. Enjoy the
                    convenience of boat rides and game drive tracks just a
                    stone's throw away. Perfect for families, friends, couples,
                    and corporate groups, our prime location ensures an
                    unforgettable experience for all.
                  </p>
                  <button
                    @click="$router.push({ path: '/experiences' })"
                    class="btn btn-lg content-btn mt-5 segoe"
                  >
                    DISCOVER PARADISE
                  </button>
                </div>
              </div>
              <div class="bg-image">
                <!-- <img src="../../../assets/images/stunning-views2.webp" /> -->
                <!-- <img src="../../../assets/images/prime-location.webp" /> -->
                <img src="../../../assets/images/national-park.webp" />


              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<style scoped>
.the-best-alternative {
  background-color: #ddd0a9;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
}
.the-best-alternative-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
}
.the-best-alternative-grid > div {
  display: flex;
  align-items: center;
}
.the-best-alternative-grid > div h2 {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 800;
  color: #17351e;
}
.the-best-alternative-grid > div p {
  font-size: 1.2em;
  font-weight: 300;
  color: #17351e;
}
.the-best-alternative-grid > div:nth-child(2) p {
  font-size: 1.2em;
  font-weight: 400;
}
.acacia-btn {
  background-color: transparent;
  color: #17351e;
  border: 2px solid #17351e;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
}
.acacia-btn:hover {
  background-color: #17351e;
  color: white !important;
  transition: 0.5s;
}
.acacia-btn-inverse {
  background-color: #17351e;
  color: white !important;
  border: 2px solid #17351e;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
}
.acacia-btn-inverse:hover {
  background-color: transparent;
  color: #17351e;
  transition: 0.5s;
}
.the-lodge-grid-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.the-lodge-grid-section > div {
  min-height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.the-lodge-grid-section > div:nth-child(2),
.the-lodge-grid-section > div:nth-child(3),
.the-lodge-grid-section > div:nth-child(6) {
  padding: 10%;
}

.the-lodge-grid-section > .bg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.the-lodge-grid-section h2 {
  font-size: 1.8em;
  font-weight: 400 !important;
  color: #17351e;
}

.the-lodge-grid-section .heading-two {
  font-size: 2.5em;
  font-weight: 400;
  color: #17351e;
  text-align: left;
}

.the-lodge-grid-section .content-paragraph {
  font-size: 19px;
  font-weight: 400;
  color: #636161;
  text-align: left;
}

.the-lodge-grid-section .content-btn {
  background-color: transparent;
  color: #17351e;
  border: 2px solid #17351e;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
  margin-top: 20px !important;
}

.the-lodge-grid-section .content-btn:hover {
  background-color: #17351e;
  color: white !important;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .the-lodge-grid-section > div {
    min-height: 50vh;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .the-lodge-grid-section > div {
    min-height: 50vh;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .the-lodge-grid-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .the-lodge-grid-section > div {
    min-height: 60vh;
  }

  .the-lodge-grid-section > div:nth-child(3) {
    grid-row: 4;
  }

  .the-lodge-grid-section > div:first-child,
  .the-lodge-grid-section > div:nth-child(4) {
    padding: 30px 20px;
  }

  .the-lodge-grid-section h2 {
    font-size: 1.5em;
  }

  .the-lodge-grid-section .heading-two {
    font-size: 1.8em;
  }

  .the-lodge-grid-section .content-paragraph {
    font-size: 0.8em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .the-best-alternative {
    background-color: #ddd0a9;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2 {
    text-align: center;
  }
  .the-best-alternative-grid > div p {
    text-align: center;
  }

  .to-the-center-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .to-the-center-mobile > div {
    width: 300px;
  }

  .the-lodge-grid-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .the-lodge-grid-section > div {
    min-height: 60vh;
  }

  .the-lodge-grid-section > div:nth-child(3) {
    grid-row: 4;
  }

  .the-lodge-grid-section > div:first-child,
  .the-lodge-grid-section > div:nth-child(4) {
    padding: 30px 20px;
  }

  .the-lodge-grid-section h2 {
    font-size: 1.5em;
  }

  .the-lodge-grid-section .heading-two {
    font-size: 1.8em;
  }

  .the-lodge-grid-section .content-paragraph {
    font-size: 0.8em;
  }
}
</style>