<template>
  <div>
    <section>
        <article class="contact_body pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <h2
                  class="text-center"
                  style="font-size: 1.5em; font-weight: 600"
                >
                  
                  We're Here to Help; Reach out to us with any questions or to make a reservation.
                  Our friendly staff is ready to assist you and ensure your stay is exceptional.
                </h2>
                <div
                  class="w-100 d-flex align-items-center justify-content-center"
                >
                  <div style="width: 200px">
                    <el-divider><i class="el-icon-eleme"></i></el-divider>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>

            <section>
              <article>
                <div class="help_grid">
                  <div>
                    <el-card
                      shadow="hover"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <div class="text-center contact-content">
                        <h3 class="m-0 p-0">Address</h3>
                        <span>
                          Acacia wilderness, RV4W+X9H, Mweya
                        </span>
                      </div>
                    </el-card>
                  </div>
                  <div>
                    <el-card
                      shadow="hover"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <div class="text-center contact-content">
                        <h3 class="m-0 p-0">Phone Number</h3>
                        <span>+256 700 817 268, </span>
                        <span>+256 765 708 329</span>
                      </div>
                    </el-card>
                  </div>
                  <div>
                    <el-card
                      shadow="hover"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <div class="text-center contact-content">
                        <h3 class="m-0 p-0">Email Address</h3>
                        <span>info@acaciawildernessmweya.com, </span>
                        <span>reservations@acaciawildernessmweya.com</span>
                      </div>
                    </el-card>
                  </div>
                </div>
              </article>
            </section>

            <section>
              <article>

                <div class="row mt-5 mb-3">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="text-center" style="font-size: 1.2em; font-weight: 600">
                      <p>
                        
                        Find our location and get directions to Acacia Wilderness Mweya. Use our easy-to-use contact form to send us
                        a message directly.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="contact_form">
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                      <el-form
                        :model="contactForm"
                        :rules="rules"
                        ref="contactForm"
                        label-width="120px"
                        class="demo-contactForm"
                        label-position="top"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item label="First Name" prop="firstName">
                              <el-input
                                v-model="contactForm.firstName"
                                placeholder="First Name"
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Last Name" prop="lastName">
                              <el-input
                                v-model="contactForm.lastName"
                                placeholder="Last Name"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item label="Email" prop="email">
                              <el-input
                                v-model="contactForm.email"
                                placeholder="Email"
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Phone Number" prop="phone">
                              <el-input
                                v-model="contactForm.phone"
                                placeholder="Phone"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <el-form-item label="Message" prop="message">
                              <el-input
                                type="textarea"
                                v-model="contactForm.message"
                                placeholder="Message..."
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="text-center">
                          <el-form-item class="m-0">
                            <el-button
                              class="content-btn"
                              @click="submitForm('contactForm')"
                              >Submit</el-button
                            >
                          </el-form-item>
                        </div>
                      </el-form>
                    </div>
                    <div class="col-md-2"></div>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </article>
      </section>

      <section>
        <article>
          <div class="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.795788103988!2d29.89340777447716!3d-0.19255883540766264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19df4500015eee2f%3A0xd31b11b9761f392c!2sAcacia%20wilderness!5e0!3m2!1sen!2sug!4v1728299336741!5m2!1sen!2sug" 
              width="100%"
              height="100%" 
              style="border:0;" 
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </div>
        </article>
      </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      contactForm: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'Please input First Name',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'Please input Last Name',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input Email',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input Phone',
            trigger: 'change',
          },
        ],
        message: [
          {
            required: true,
            message: 'Please input Message',
            trigger: 'blur',
          },
        ],
      },
    
    };
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            let request = await this.$http.post(
              `contact-us`, {
                name: `${this.contactForm.firstName} ${this.contactForm.lastName}`,
                phone: this.contactForm.phone,
                email: this.contactForm.email,
                message: this.contactForm.message
              }
            );
            if (
              request.data.success &&
              request.data.message == "MESSAGE_CREATED_SUCCESSFULLY"
            ) {
              this.resetForm(formName);
              return this.$notify.success({
                title: 'Success',
                message: 'Your Message has been sent successfully'
              });

            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify.error({
                title: 'Connection failed',
                message: 'A network error occurred, please try again.'
              });
            }
            this.$notify.error({
                title: 'Loading Failed',
                message: 'Unable to send this message, Please try again later'
              });
          } finally {
            this.loading = false;
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Unable to send this message.'
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    font-family: 'Lato', sans-serif;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding:0px;
    font-weight: 300;
  }

  h3{
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 15px;
  }

.contact_body {
  padding-top: 200px;
}

.contact_body .help_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.contact_body .help_grid > div {
  background-color: #fcf9f5;
  height: 180px;
}

.contact_body .help_grid .contact-content span {
  font-weight: 300;
  font-size: 0.8em;
}

.contact_form {
  background-color: #fcf9f5;
  margin-top: 20px;
  padding: 50px 50px;
}

.contact-map {
  background-color: white;
  height: 100vh;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
}

h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 15px;
}

.content-btn {
  background-color: transparent;
  color: #3D290A;
  border: 2px solid #3D290A;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 250px;
}

.content-btn:hover {
  background-color: #3D290A;
  color: white !important;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
</style>
