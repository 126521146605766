<template>
  <div>
    <article>
      <CarouselComponent></CarouselComponent>
    </article>

    <section>
      <article>
        <HomeComponent></HomeComponent>
      </article>
    </section>

    <section>
      <article class="home-image">
        <!-- <img src="../../assets/images/occupancy/long.jpg" /> -->
      </article>
    </section>

    <section>
      <article class="acacia-container our-rooms">
        <div class="text-center">
          <h2 style="color:#17351E; font-weight:500">Our Rooms</h2>
          <p style="color:rgb(24, 139, 51); font-size:19px">Your Sanctuary in the wild</p>
        </div>
        <br>
        <div class="our-rooms-grid">
          <div>
            <div class="our-room-img">
              <img src="../../assets/images/single-occupancy.webp" />
            </div>
            <div class="pt-4">
              <p style="color:#17351E">Single Occupancy</p>
              <!-- <el-button>Explore More  <i class="el-icon-right"></i></el-button> -->
              <button class="btn btn-lg content-btn mt-5 segoe" @click="$router.push({ path: 'accommodation' })">
                
                    Explore More
                    <i class="el-icon-right"></i>
                  </button>
            </div>
          </div>
          <div>
            <div class="our-room-img">
              <img src="../../assets/images/double_occupancy.webp" />
            </div>
            <div class="pt-4">
              <p  style="color:#17351E">Double Occupancy</p>
              <!-- <el-button>Explore More  <i class="el-icon-right"></i></el-button> -->

              <button class="btn btn-lg content-btn mt-5 segoe" @click="$router.push({ path: 'accommodation' })">
                
                Explore More
                <i class="el-icon-right"></i>
              </button>
            </div>
          </div>
          <div>
            <div class="our-room-img">
              <img src="../../assets/images/accomodation-1.webp" />
            </div>
            <div class="pt-4">
              <p style="color:#17351E">Family Occupancy</p>
              <!-- <el-button>Explore More <i class="el-icon-right"></i></el-button> -->
              <button class="btn btn-lg content-btn mt-5 segoe" @click="$router.push({ path: 'accommodation' })">
                
                Explore More
                <i class="el-icon-right"></i>
              </button>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article>
        <ReviewComponent></ReviewComponent>
      </article>
    </section>
  </div>
</template>

<script>
import HomeComponent from "../../components/website/home/home-component.vue";
import CarouselComponent from "../../components/website/home/carousel-component.vue";
import ReviewComponent from "../../components/website/home/review-component.vue";

export default {
  components: {
    HomeComponent,
    CarouselComponent,
    ReviewComponent,
  },

  data() {
    return {};
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    font-family: 'Lato', sans-serif;
  }
  .acacia-container {
    padding-left: 8%;
    padding-right: 8%;
  }

  .content-btn {
  background-color: transparent;
  color: #17351e;
  border: 2px solid #17351e;
  border-radius: 0px;
  font-size: 1em;
  margin: 0px !important;
  transition: 0.5s;
  width: 250px;
  margin-top: 10px !important;
}

.content-btn:hover {
  background-color: #17351e;
  color: #DDD0A9 !important;
  transition: 0.5s;
}

  .home-image{
    /* background-image: url('../../assets/images/acacia-aerial-1.webp'); */
    background-image: url('../../assets/images/aerial-2.webp');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100vh;
    width: 100%;
  }

  /* .home-image img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
  } */

  .our-rooms {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #DDD0A9
  }
  .our-rooms h2 {
    font-size: 2.5em;
    font-weight: 600;
  }
  .our-rooms p {
    font-size: 1.5em;
  }

  .our-rooms-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
  }

  .our-room-img img{
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .our-rooms-grid p{
    font-size: 2em;
    font-weight: 600;
  }

  .our-rooms-grid button{
    font-size: 1.5em;
    font-weight: 500;
  }

  

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  

}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  

}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  
  .acacia-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .home-image{
    height: 50vh;
  }

  .home-image img{
    height: 50vh;
    object-fit: cover;
  }

  .our-rooms {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .our-rooms h2 {
    font-size: 2.5em;
    font-weight: 600;
  }
  .our-rooms p {
    font-size: 1.5em;
  }

  .our-rooms-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .our-room-img img{
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .our-rooms-grid p{
    font-size: 2em;
    font-weight: 600;
  }

  .our-rooms-grid button{
    font-size: 1.5em;
    font-weight: 500;
  }
}
</style>
