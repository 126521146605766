<template>
  <div>
    <section>
      <article
        style="
          padding-top: 160px;
          border: 1px solid transparent;
        "
      >
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="dining_introduction">
            <p class="locus_word_style">
              <small>Acacia Wilderness Mweya  UGANDA</small>
            </p>
            <h2 style="font-weight: 600; font-size: 2em">                            
              {{ category.categoryName }}
            </h2>
            <p style="font-weight: 300">
              {{ category.description }}
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
          <div class="room_photos_grid">
            <div>
              <img
                :src="category.categoryImage"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div>
              <div v-for="image in category.slideRoomImages" :key="image.path">
                <img :src="image.path" class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
          <h2 style="font-weight: 600; font-size: 1.5em">
            What this place offers
          </h2>
          <div class="row">
            <div class="col-md-6">
              <ul style="color: grey">
                <div class="row">
                  <div class="col-md-6">
                    <li>
                      <small>
                        <i
                          class="fas fa-shower"
                          style="color: #d96b12; padding-right: 5px"
                        ></i>
                        Bathroom Amenities
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-wifi"
                          style="color: #d96b12; padding-right: 5px"
                        ></i>
                        Free High Speed Wi-Fi Internet
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-tv"
                          style="color: #d96b12; padding-right: 5px"
                        ></i>
                        Satellite TV
                      </small>
                    </li>
                  </div>
                  <div class="col-md-6">
                    <li>
                      <small>
                        <i
                          class="el-icon-phone-outline"
                          style="color: #d96b12; padding-right: 5px"
                        ></i>
                        Room service available
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-swimming-pool"
                          style="color: #d96b12; padding-right: 5px"
                        ></i>
                        Free Swimming Pool Access
                      </small>
                    </li>
                    <li>
                      <small>
                        <i
                          class="fas fa-tshirt"
                          style="color: #d96b12; padding-right: 5px"
                        ></i>
                        Laundry service at a cost
                      </small>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            <div
              class="col-md-6"
              style="display: flex; justify-content: flex-end"
            >
              <div class="checkout_box">
                <div
                  class="mb-2"
                  style="display: flex; justify-content: space-between"
                >
                <!-- {{ datesDifferenceResults_value }}
                {{ category.pricePerNight }} -->
                  <span style="font-weight: 600; font-size: 1.2em">
                    $
                    {{
                      formatNumber(
                        datesDifferenceResults_value * category.pricePerNight
                      )
                    }}
                  </span>
                  <span style="font-weight: 300; font-size: 1em">
                    /{{ datesDifferenceResults_value }}
                    {{ datesDifferenceResults_value == 1 ? "Night" : "Nights" }}
                  </span>
                </div>

                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  label-position="top"
                  ref="ruleForm"
                  label-width="120px"
                  class="demo-ruleForm"
                >
                  <div style="display: flex">
                    <div class="block">
                      <el-form-item prop="checkInDate">
                        <el-date-picker
                          v-model="ruleForm.checkInDate"
                          type="date"
                          @change="changedValue()"
                          placeholder="CheckIn date"
                          style="width: 100%"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="block">
                      <el-form-item prop="checkOutDate">
                        <el-date-picker
                          v-model="ruleForm.checkOutDate"
                          type="date"
                          @change="changedValue()"
                          placeholder="CheckOut date"
                          style="width: 100%"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>

                  <div style="display: flex">
                    <div class="block">
                      <el-form-item label="Adults" prop="numberOfAdults">
                        <el-input-number
                          v-model="ruleForm.numberOfAdults"
                          controls-position="right"
                          style="width: 100%"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                        >
                        </el-input-number>
                      </el-form-item>
                    </div>
                    <div class="block">
                      <el-form-item
                        label="Children"
                        prop="numberOfChildren"
                      >
                        <el-input-number
                          v-model="ruleForm.numberOfChildren"
                          controls-position="right"
                          style="width: 100%"
                          @change="handleChange"
                          :min="0"
                          :max="10"
                        >
                        </el-input-number>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row mt-4 mb-5">
                    <div class="col-md-12">
                      <el-form-item>
                        <el-button
                          type="primary"
                          @click="submitForm('ruleForm')"
                          class="btn btn-brown btn-md"
                          style="width: 100%"
                        >
                          PROCEED TO RESERVATION
                          <span class="el-icon-d-arrow-right"></span>
                        </el-button>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table_drawer: false,
      direction: "rtl",
      categoryID: this.$route.params.categoryID,
      category: {},
      checkInDate: "",
      checkOutDate: "",
      datesDifferenceResults_value: "",
      adults_num: 1,
      children_num: 1,

      options: [
        {
          value: "1",
          label: "1 Guest",
        },
        {
          value: "2",
          label: "2 Guests",
        },
        {
          value: "3",
          label: "3 Guests",
        },
        {
          value: "4",
          label: "4 Guests",
        },
        {
          value: "5",
          label: "5 Guests",
        },
      ],
      value: "",

      ruleForm: {
        categoryName: "",
        pricePerNight: "",
        checkInDate: "",
        checkOutDate: "",
        numberOfAdults: "",
        numberOfChildren: "",
      },

      rules: {
        checkInDate: [
          {
            type: "date",
            required: true,
            message: "Please pick Check-In Date",
            trigger: "change",
          },
        ],
        checkOutDate: [
          {
            type: "date",
            required: true,
            message: "Please pick Check-out Date",
            trigger: "change",
          },
        ],
        numberOfAdults: [
          {
            required: true,
            message: "Please select number of Adults",
            trigger: "change",
          },
        ],
        numberOfChildren: [
          {
            required: true,
            message: "Please select number of Children",
            trigger: "change",
          },
        ],
      },
    };
  },

  watch: {
    "$route.params.categoryID": {
      handler: function (room) {
        this.getRoomCategoryById();
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.getRoomCategoryById();
    this.datesDifferenceResults();
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    datesDifferenceResults() {
      if (
        this.ruleForm.checkInDate != "" &&
        this.ruleForm.checkOutDate != ""
      ) {
        const date1 = new Date(`${this.ruleForm.checkInDate}`);
        const date2 = new Date(`${this.ruleForm.checkOutDate}`);
        const absDate1 = Math.abs(date1);
        const absDate2 = Math.abs(date2);
        const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
        const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
        const diffDays = ceilDate2 - ceilDate1;
        this.datesDifferenceResults_value = diffDays;
      } else {
        this.datesDifferenceResults_value = 1;
      }
    },

    changedValue() {
      const date1 = new Date(`${this.ruleForm.checkInDate}`);
      const date2 = new Date(`${this.ruleForm.checkOutDate}`);
      const absDate1 = Math.abs(date1);
      const absDate2 = Math.abs(date2);
      const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
      const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
      console.log("In: " + ceilDate1);
      console.log("Out: " + ceilDate2);
      if (ceilDate2 < ceilDate1) {
        this.ruleForm.checkOutDate = "";

        this.$notify({
          categoryName: "Past Date",
          message: "Check out date can't be lower than Check In date.",
          type: "warning",
        });
      }
      this.datesDifferenceResults();
    },

    handleClose(done) {
      done();
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.categoryName = this.category.categoryName;
          this.ruleForm.pricePerNight =
            this.category.pricePerNight * this.datesDifferenceResults_value;

          localStorage.setItem("category_details", JSON.stringify(this.ruleForm));
          this.$router.push({
            name: "Reservations",
            query: { plan: this.$route.params.categoryID },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleChange(value) {
      console.log(value);
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(categoryID) {
      this.$router.push({ path: `accommodation-detail/${categoryID}` });
    },
    

    async getRoomCategoryById() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `categories/${this.$route.params.categoryID}`
        );
        if (request.data.success && request.data.message == "ROOM_CATEGORY_FETCHED_SUCCESSFULLY") {
          this.category = request.data.category;
          // this.roomCategoryForm.categoryName = this.category.categoryName;
          // this.roomCategoryForm.pricePerNight = this.category.pricePerNight;
          // this.roomCategoryForm.maxOccupancy = this.category.maxOccupancy;
          // this.roomCategoryForm.description = this.category.description;
          // this.selectedFile = this.category.categoryImage;
          // this.selectedFileOne = this.category.slideRoomImages[0].path;
          // this.selectedFileTwo = this.category.slideRoomImages[1].path;
          // this.selectedFileThree = this.category.slideRoomImages[2].path;
          // this.selectedFileFour = this.category.slideRoomImages[3].path;
          // this.selectedSlideFiles = this.category.slideRoomImages.map(
          //   (item) => {
          //     return item.path;
          //   }
          // );
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        return this.$notify({
          categoryName: "Operation Failed",
          message: "Unable to fetch Category now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.locus_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.dining_introduction {
  width: 60%;
}

.room_photos_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.room_photos_grid > div {
  height: 400px;
}

.room_photos_grid > div:first-child {
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.room_photos_grid > div:first-child img {
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.room_photos_grid > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.room_photos_grid > div:nth-child(2) > div {
  height: 100%;
  width: 100%;
  border-radius: 5px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.room_photos_grid > div:nth-child(2) > div img {
  height: 195px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

ul li {
  margin-bottom: 15px;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

.btn-brown {
  background-color: #d96b12;
  color: white;
  width: 100%;
}
.btn-view-more {
  background-color: white;
  border: 1px solid #d96b12;
  width: 250px;
}

.rooms_grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  border-radius: 5px;
}

.rooms_grid > div {
  text-align: left;
  padding: 20px;
  border-radius: 5px;
}

.rooms_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.rooms_grid .rooms_image img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.rooms_grid ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.checkout_box {
  width: 60%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
  
  .checkout_box {
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
  
  .checkout_box {
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .room_photos_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  
  .checkout_box {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .room_photos_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  
  .checkout_box {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
  }
}
</style>


<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>