<template>
    <div>
        <section>
            <article class="accommodation-header parallax-container">
                <img :src="selectedImage" ref="parallaxImage" class="parallax-image"
                    :style="{ transform: `translateY(${parallax}px)` }" 
                />
                <div class="image-description">
                    <div class="">
                        <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10 text-center">
                            <h1>{{ title }}</h1>
                            <p>
                                {{ paragraph }}
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        parallax: 0,
      };
    },
  
    props: {
      selectedImage: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      paragraph: {
        type: String,
        required: true,
      }
    },
      
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
      handleScroll() {
          this.parallax = window.pageYOffset * 0.7;
      },
    },
  };
  </script>
  
  <style scoped>
h1{
  
  font-weight: 400 !important;
}
p{
  font-size: 18px !important;
  font-weight: 300 !important;
  color: #e6e1cf !important;
}
  .parallax-container {
      position: relative;
      height: 100vh;
      overflow: hidden;
  }
  
  .parallax-container>img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
  }
  
  .parallax-image {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
  }
  .accommodation-header {
      height: 100vh;
      width: 100%;
      position: relative;
  }
  
  .accommodation-header > div{
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.878) 100%
    );
    color: white;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
  
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 150px;
  }
  
  .accommodation-header > div>div{
    width: 60%;
  }
  .accommodation-header h1{
    font-size: 3em;
    font-weight: 800;
  }
  .accommodation-header p{
    font-size: 1.5em;
    font-weight: 500;
  }
  
 
  
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    
    .parallax-container {
        height: 80vh;
    }

    .parallax-container>img {
        height: 80vh;
    }

    .parallax-image {
        height: 80vh;
    }
    .accommodation-header {
        height: 80vh;
    }
    .accommodation-header > div{
      padding-bottom: 50px;
    }
    .accommodation-header > div>div{
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
    }
    .accommodation-header h1{
      font-size: 2.5em;
      font-weight: 800;
    }
    .accommodation-header p{
      font-size: 1em;
      font-weight: 500;
    }
  }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
    .parallax-container {
        height: 80vh;
    }

    .parallax-container>img {
        height: 80vh;
    }

    .parallax-image {
        height: 80vh;
    }
    .accommodation-header {
        height: 80vh;
    }
    .accommodation-header > div{
      padding-bottom: 50px;
    }
    .accommodation-header > div>div{
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
    }
    .accommodation-header h1{
      font-size: 2.5em;
      font-weight: 800;
    }
    .accommodation-header p{
      font-size: 1em;
      font-weight: 500;
    }
  }
  </style>
  